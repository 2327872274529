import {
  REMOVE_EVENT,
  SET_APP_ERROR,
  SET_APP_LOADING,
  SET_EVENT,
  SET_SPOTLIGHT,
  SET_LIFF_ID,
  SET_SOCIAL_AUTHENTICATED,
  SET_USER,
  SET_FIRST_LOAD,
  SET_NOTIFICATION_COUNT,
  SET_STORE_SELECTION
} from "./global.types";

export const setAppLoading = (data) => {
  return {
    type: SET_APP_LOADING,
    payload: data,
  };
};

export const setAppError = (data) => {
  return {
    type: SET_APP_ERROR,
    payload: data,
  };
};

export const setSpotlight = (data) => {
  return {
    type: SET_SPOTLIGHT,
    payload: data,
  };
};
export const setEvent = (data) => {
  return {
    type: SET_EVENT,
    payload: data,
  };
};

export const setStoreSelection = (data) => {
  return {
    type: SET_STORE_SELECTION,
    payload: data,
  };
};


export const removeEvent = () => {
  return {
    type: REMOVE_EVENT,
    payload: null,
  };
};

export const setSocialAuthenticated = (data) => {
  console.log('setauhtenctaiobe ', data)
  return {
    type: SET_SOCIAL_AUTHENTICATED,
    payload: data,
  };
};

export const setLIFF_ID = (data) => {
  return {
    type: SET_LIFF_ID,
    payload: data,
  };
};

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};
export const setFirstLoad = (data) => {
  return {
    type: SET_FIRST_LOAD,
    payload: data,
  };
};

export const setNotificationsCount = (data) => {
  return {
    type: SET_NOTIFICATION_COUNT,
    payload: data,
  };
};
