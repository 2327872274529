import { IonButton } from "@ionic/react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import MembersService from "../../services/members.service";
import { connect } from "react-redux";
import { InformModal } from "../../../components/inform-modal/inform-modal";
import {fetchBasket, fetchOffers} from "../../../redux/offer-list/offer-list.actions";
import { setEvent } from "../../../redux/global/global.actions";
import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import yb from "../screen2/6.png";
import iy from "../screen2/10.png";

interface Props {
  state: any;
  offer?: any;
  stores: any;
  added?: boolean;
  basketMode?: any;
  basket: any[];
  offers: any[];
  close?: Function;
  fetchOffers: Function;
  fetchBasket?: Function;
  callToActionButtonDisabled?: boolean;
  onCallActionButtonClick?: Function;
  offerDetails: any;
  retailer: any;
  setEvent: Function;
}

interface List {
  items: string[];
}

const JOrderedList = (props: List) => {
  return (
    <ol className="list-disc px-4">
      {props.items.map((item) => (
        <li
          key={item}
          dangerouslySetInnerHTML={{ __html: item }}
          className="mt-4 text-xs"
          style={{ color: "#777777" }}
        ></li>
      ))}
    </ol>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
    count: state.counter.count,
    offers: state.offerList.offers,
    stores: state.stores,
    basket: state.offerList.basket,
    offerDetails: state.offerDetails,
    retailer: state.retailers.selected,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchBasket: () => dispatch(fetchBasket()),
    fetchOffers: () => dispatch(fetchOffers()),
    setEvent: (data: any) => dispatch(setEvent(data))
  };
};

const Screen18 = (props: Props) => {
  //   console.log("Props: ", props);
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;
  const [loading, setLoading] = useState(false);
  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const history = useHistory();
  // const [store, setStore] = useState<any>(null);
  //   const [offer, setOffer] = useState<any>(null);
  const { id } = useParams<any>();
  const location = useLocation();
  //   console.log("Pathname: ", location.pathname);

  const basketOfferIds = props.basket.map((b) => b.offer.id);
  // const offerMain = props.offers && props.offers.filter((b)=>{
  //   return b.id===props.offerDetails.selectedOffer.id
  // })[0];
  // console.log('offerMain',offerMain)
  const selectedStore = props.stores.selectedStore;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);


  useEffect(() => {
    props.setEvent("OFFER_DETAILS")
    // eventBus.dispatch("GTM_EVENT", {
    //   eventName: "OFFER_DETAILS",
    //   data: props.state,
    // });
  }, [props.offerDetails.selectedOffer]);

  const store = props.stores.selectedStore;
  if (!store || !store.name) {
    return null;
  }


  const offer = props.offerDetails.selectedOffer;
  let overviewText = offer?.overview.split('\n').map((item: any, i: any) => {
    if (item) {
      return <p key={i} className="my-1">{item}</p>
    } else {
      return <br />
    }
  });
  let disclaimerText = offer?.disclaimer.split('\n').map((item: any, i: any) => {
    if (item) {
      return <p key={i} className="my-1">{item}</p>
    } else {
      return <br />
    }
  });
  const isAddedToBasket = basketOfferIds.indexOf(props.offerDetails.selectedOffer.id) > -1;
  const is_rolling = offer.is_rolling ? offer.is_rolling : false;
  return (
    <ScrollpageWithHeader
      title="クーポン情報"
      onCloseClick={props.close}
      id="screen-18"
    >
      <div className="flex flex-col items-center justify-start flex-1 overflow-auto details">
        {offer && offer.detail_url &&
            <div className="relative overflow-hidden md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
              {/*<img*/}
              {/*    className="h-full w-full object-cover"*/}
              {/*    src={video.main_picture_url}*/}
              {/*    alt=""*/}
              {/*/>*/}
              <video controls  loop autoPlay  muted className="w-full object-contain" >
                <source src={offer.detail_url} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>

            </div>

          // <Carousel spotlights={props.spotlights}/>
        }
        {offer && !offer.detail_url &&
        <img src={offer?.picture_url} />
        }
        <div className="flex flex-col w-full px-6 pt-6">
          <div className="flex items-center justify-start pb-4">
            <img
              className="items-center justify-center rounded-md flex overflow-hidden object-contain"
              style={{
                height: "2.875rem",
                width: "3.875rem",
              }}
              src={imageURL[store.retailer.id]}
            />
            <div className="pl-4 text-sm">{store.name}</div>
          </div>
          <div className="pt-2 font-bold">
            <span className="text-4xl">{offer ? offer.discount_value : "NA. "}</span><span className="text-base">ポイント</span>
          </div>
          <div className="pt-1 font-semibold text">{offer?.title}</div>
          <div className="pt-1 text-sm" style={{ color: "#616161" }}>{offer?.subtitle}</div>
          {/* <div className="pt-2 text-xs" style={{ color: "#949494"}}>
            {
              disclaimerText.map((item:any)=>{
                return item
              })
            }
          </div> */}
          <br/>
          <div className="text-xs text-gray-600">
            {/*{offer.is_nextgen_offer && <div>※クーポン利用期限は、「バスケットに追加」後にご確認いただけます。<br/></div>}*/}
            {/*{*/}
            {/*  // offer.is_nextgen_offer &&*/}
            {/*    is_rolling &&*/}

            {/*    <div>クーポンはバスケットに追加してから14日以内にご利用ください。14日を経過しますとバスケットから外されます。利用のためには再度バスケットに追加することが必要ですのでご注意ください。*/}
            {/*      <br/><br/>*/}
            {/*      ※使用したクーポンはご利用から4日後に再度ご利用可能になります。ただし、日程は前後する可能性があります。また、クーポンによっては再使用可能回数に制限がある場合がございます。*/}
            {/*      <br/><br/>*/}
            {/*      ※店舗によりクーポン対象商品が取り扱われていない場合がございます。*/}
            {/*      <br/><br/>*/}
            {/*      以上ご了承ください。*/}


            {/*    </div>*/}

            {/*}*/}

            {/*{*/}
            {/*  // !offer.is_nextgen_offer &&*/}
            {/*    !is_rolling &&*/}
            {/*    <div>※クーポン利用期限は、クーポンセット後に「バスケット」ページでご確認いただけます。*/}
            {/*      <br/>*/}
            {/*      <br/>*/}
            {/*      ※店舗により取扱い商品が異なる場合がございます。店舗で取扱いのある商品にのみ、本クーポンはご利用いただけます。*/}
            {/*      <br/>*/}
            {/*      <br/>*/}
            {/*      ※品切れの際はご容赦ください。*/}
            {/*      <br/>*/}
            {/*      <br/>*/}
            {/*      以上ご了承ください。*/}
            {/*    </div>}*/}


            {/*{!offer.is_nextgen_offer &&*/}
            {/*    <div>※使用したクーポンはご利用日から4日後に再度ご利用可能になります。ただし、日程は前後する可能性があります。また、クーポンによっては再使用可能回数に制限がある場合がございます。<br/>*/}
            {/*    </div>}*/}
            {/*<br/>*/}
            {/*※店舗によりクーポン対象商品が取り扱われていない場合がございます。*/}
            {/*<br/><br/>*/}
            {/*以上ご了承ください。*/}
            {/*  New Text */}
            {!offer.is_nextgen_offer && offer.codes.length == 0 &&
                <div>※クーポン利用期限は、クーポンセット後に「バスケット」ページでご確認いただけます。</div>
            }
            {(offer.codes.length > 0 || offer.is_nextgen_offer) &&
                <>
                  <div>※クーポン利用期限は、クーポンセット後に「バスケット」ページでご確認いただけます。</div>
                  <br/>
                  <div>※一度のお会計で、本クーポンと店頭で発券されるカタリナクーポンは併用できません。</div>
                  <br/>
                  <div>※店舗により取扱い商品が異なる場合がございます。店舗で取扱いのある商品にのみ、本クーポンはご利用いただけます。</div>
                  <br/>
                  <div>※店頭で対象商品が品切れの際はご容赦ください。</div>
                  <br/>
                  <div>以上ご了承ください。</div>
                </>
            }

          </div>
          <div className="pt-2 text-xs">
            {
              overviewText.map((item: any) => {
                return item
              })
            }
          </div>
        </div>
      </div>
      <div className="text-xs opacity-0 h-1 overflow-hidden">
        <JOrderedList
            items={[
              "&nbsp;",
          ]}
        />
      </div>
      <IonButton
        disabled={loading || isAddedToBasket}
        onClick={() => {
          if (
            !selectedStore ||
            selectedStore.id === null ||
            selectedStore.id === "undefined"
          ) {
            // console.log("Please select a store");
            setErrorMessage("お店を選択してください");
            setOpenErrorModel(true);
            return false;
          }
          setLoading(true)
          MembersService.addCouponToBasket(props.offerDetails.selectedOffer.id, selectedStore)
            .then((response) => {
              setLoading(false)
              // eventBus.dispatch("refetchBasket", {});
              props.fetchBasket()
              props.fetchOffers();
              history.goBack();
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                setErrorMessage(error.response.data.error);
                setOpenErrorModel(true);
              }
            });
        }}
        expand="full"
        size="large"
        className=""
      >
        {isAddedToBasket ? "セット済み":"セットする"}


      </IonButton>
      {openErrorModel && (
        <InformModal
          message={errorMessage}
          open={openErrorModel}
          setOpen={setOpenErrorModel}
        />
      )}

    </ScrollpageWithHeader>
  );
};

export const OfferDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen18);
