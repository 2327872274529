import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonBadge,
  IonRouterOutlet,
} from "@ionic/react";

import home from "./home.png";
import coupons from "./coupons.png";
import basket from "./basket.png";
import account from "./account.png";
import notification from "./notification.png";

import { Redirect, Route } from "react-router-dom";
import { Screen14 } from "../screens/screen14/screen14";
import { Screen16 } from "../screens/screen16/screen16";
import { Screen24 } from "../screens/screen24/screen24";
import { ScreenCoupons } from "../screens/screen-coupons/screen-coupons";
import MembersService from "../services/members.service";
import { useEffect, useRef, useState } from "react";
import { eventBus } from "../event-bus/event-bus";
import { connect } from "react-redux";
import {
  fetchBasket,
  fetchOffers,
  offerListLoading,
  setBasketList,
  setOfferList,
} from "../../redux/offer-list/offer-list.actions";
import { setCategories } from "../../redux/categories/categories.actions";
import {
  setFavoriteStores,
  setSelectedStore,
  setStoreLoading,
} from "../../redux/stores/stores.actions";
import {setAccountMode, setHolderCards} from "../../redux/holder-cards/holder-cards.actions";
import { setMemberWallet } from "../../redux/wallet/wallet.actions";
import { setSelectedTab } from "../../redux/tabs/tabs.actions";
import {
  removeEvent,
  setAppLoading,
  setEvent,
  setFirstLoad,
  setNotificationsCount,
  setUser,
  setSpotlight
} from "../../redux/global/global.actions";
import {
  selectRetailerForAccount,
  setRetailer,
  setRetailers,
  setSelectionMode
} from "../../redux/retailers/retailers.actions";
import { Screen24Retailer } from "../screens/screen24-retailer/screen24-retailer";
import { AccountEditing } from "../screens/account-screens/account-editing/account-editing";
import { AccountFaq } from "../screens/account-screens/account-faq/account-faq";
import { AccountInquiry } from "../screens/account-screens/account-inquiry/account-inquiry";
import { AccountPrivacy } from "../screens/account-screens/account-privacy/account-privacy";
import { AccountSettings } from "../screens/account-screens/account-settings/account-settings";
import { AccountTerms } from "../screens/account-screens/account-terms/account-terms";
import { AccountRetailerAbout } from "../screens/account-screens/account-retailer-about/account-retailer-about";
import { setAllMemberWallet } from "../../redux/allwallet/allwallet.actions";
import { Screen23 } from "../screens/screen23/screen23";
import {setAllMemberFilteredWallet} from "../../redux/filteredAllwallet/filteredAllwallet.actions";
import {Notifications} from "../screens/notifications/notifications";
import Example from "../screens/notifications/Example";


declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const mapStateToProps = (state: any) => {
  return {
    state: state,
    count: state.counter.count,
    offerDetails: state.offerDetails,
    offers: state.offerList.offers,
    selectedTab: state.tabs.selected,
    basket: state.offerList.basket,
    selectedCategory: state.categories.selectedCategory,
    stores: state.stores,
    isAppLoading: state.global.isAppLoading,
    event: state.global.event,
    selectedStore: state.stores.selectedStore,
    cards: state.cards.holders,
    retailer:state.retailers.selected,
    user: state.global.user,
    cardsLoading: state.cards.loading,
    isStoresLoading: state.stores.loading,
    notificationsCount: state.global.notificationsCount,
    spotlightOffers: state.global.spotlightOffers
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setOfferList: (data: any) => dispatch(setOfferList(data)),
    setSpotlight: (data: any) => dispatch(setSpotlight(data)),
    setSelectionMode: (data: any) => dispatch(setSelectionMode(data)),
    setUser: (data: any) => dispatch(setUser(data)),
    setBasketList: (data: any) => dispatch(setBasketList(data)),
    setCategories: (data: any) => dispatch(setCategories(data)),
    setFavoriteStores: (data: any) => dispatch(setFavoriteStores(data)),
    offerListLoading: (data: any) => dispatch(offerListLoading(data)),
    setHolderCards: (data: any) => dispatch(setHolderCards(data)),
    setMemberWallet: (data: any) => dispatch(setMemberWallet(data)),
    setAllMemberFilteredWallet: (data: any) => dispatch(setAllMemberFilteredWallet(data)),
    setAllMemberWallet: (data: any) => dispatch(setAllMemberWallet(data)),
    setRetailers: (data: any) => dispatch(setRetailers(data)),
    setStoreLoading: (data: any) => dispatch(setStoreLoading(data)),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    setAccountMode: (data: any) => dispatch(setAccountMode(data)),
    fetchBasket: () => dispatch(fetchBasket()),
    fetchOffers: () => dispatch(fetchOffers()),
    setSelectedStore: () => dispatch(setSelectedStore()),
    setRetailer: (data: any) =>
        dispatch(setRetailer(data)),
    setEvent: (data: any) => dispatch(setEvent(data)),
    removeEvent: () => dispatch(removeEvent()),
    setFirstLoad: (data: any) => dispatch(setFirstLoad(data)),
    setNotificationsCount: (data: any) => dispatch(setNotificationsCount(data)),
  };
};

interface Props {
  state: any;
  offers: any[];
  basket: any[];
  selectedTab: string;
  setSelectedTab: Function;
  setUser: Function;
  setSelectionMode: Function;
  setOfferList: Function;
  setSpotlight: Function;
  setBasketList: Function;
  setHolderCards: Function;
  setCategories: Function;
  selectedCategory: any;
  stores: any;
  user:any;
  event: any;
  retailer:any;
  notificationsCount: any;
  setEvent: Function;
  removeEvent: Function;
  offerDetails: any;
  setFavoriteStores: Function;
  offerListLoading: Function;
  setMemberWallet: Function;
  setAllMemberWallet: Function;
  setAllMemberFilteredWallet: Function;
  setStoreLoading: Function;
  setRetailers: Function;
  fetchBasket: Function;
  fetchOffers: Function;
  setFirstLoad: Function;
  setSelectedStore: Function;
  setAppLoading: any;
  selectedStore: any;
  cards: any[];
  isStoresLoading: boolean;
  isAppLoading: boolean;
  cardsLoading: boolean;
  spotlightOffers:any;
  setRetailer: Function;
  setAccountMode: Function;
  setNotificationsCount: Function;
}

export const CouponsTabsComponent = (props: Props) => {
  const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(false);
  const [storesLoaded, setStoresLoaded] = useState<boolean>(false);
  // let nextgenArray:any[] = []
  const fetchCategories = () => {
    MembersService.getCategories(
      props.stores.selectedStore ? props.stores.selectedStore.retailer_id : props.cards.length > 0 ? props.cards[0].retailer_id : -1
    )
      .then((responseJson) => {
        let categories: any[] = [];
        if (responseJson.data.length) {
          let allCategories: any[] = [];
          categories = allCategories.concat(responseJson.data)
          categories.sort(function (a, b) {
            if (a.precedence > b.precedence) {
              return 1;
            } else if (a.precedence < b.precedence) {
              return -1;
            } else {
              return 0;
            }
            // return (a.precedence<b.precedence)
          });
          categories.unshift({
            id: 1,
            name: "全て",
            tag: "All",
            precedence: 0,
          });
          // fetchFavoriteStores();
        }
        props.setCategories(categories);
        setCategoriesLoaded(true);
      })
      .catch((error) => {
        setCategoriesLoaded(true);
        console.log(error);
      });
  };

  const fetchHolderCards = () => {
    MembersService.getHolders()
      .then((responseJson) => {
        props.setHolderCards(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSpotlights = () => {
    MembersService.getSpotlights()
        .then((responseJson) => {
          let spotlights = responseJson.data;
          let offerFilters = new Set();
          spotlights.forEach((spotlight:any) => {
            console.log(spotlight)
            if(spotlight.destination.location_type==="offer"){
              offerFilters.add(spotlight.destination.offer_location.offer_id)
            }
          });
          console.log(offerFilters)

          // props.setSpotlight({spotlights:spotlights, spotlightOffers:Array.from(offerFilters)})
          console.log(spotlights)
        })
        .catch((error) => {
          console.log(error);
        });
  }
  const fetchRetailers = () => {
    MembersService.getRetailers()
      .then((responseJson) => {
        let retailers = responseJson.data;
        // let app_retailer_id = "1";
        // retailers = retailers.filter((retailer:any) => retailer.id != parseInt(ybId))

        let app_retailer_id = localStorage.getItem("app_retailer_id");

        let id_filter:any = [];
        if(app_retailer_id){
           id_filter = app_retailer_id.split(',').map( Number );
        }


        if(id_filter.length>0){
          retailers = retailers.filter((retailer:any) => id_filter.includes(retailer.id))
        }
        props.setRetailers(retailers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchFavoriteStores = () => {
    props.setStoreLoading(true);
    MembersService.getFavoriteStores().then((response) => {
      let starStore = JSON.parse(localStorage.getItem("fav-store"));
      if (starStore) {
        let storeExists = response.data.filter(
          (c: any) => c.id === starStore.id
        )[0];
        if (!storeExists) {
          localStorage.removeItem("fav-store");
        } else {
          props.setRetailer(storeExists.retailer)
        }
      }
      props.setFavoriteStores(response.data);

      if (response.data[0]) {
        // DataService.retailer.set(response.data[0].retailer);
      }
      props.setStoreLoading(false);
      setStoresLoaded(true);
    });
  };

  // const notificationsCount = (arrayLS:any)=>{
  //
  //   let totalNextgenOffers = 0;
  //   arrayLS.forEach((instance:any)=>{
  //     totalNextgenOffers+=instance.offers.length;
  //   })
  //   console.log(totalNextgenOffers);
  //   // localStorage.setItem('nextGenOffersHistory',JSON.stringify(arrayLS))
  //   props.setNotificationsCount(totalNextgenOffers)
  //   // console.log(offerForHistory)
  // }
  const fetchOffers = async () => {
    props.offerListLoading(true);
    if (!props.selectedCategory || !props.stores.selectedStore) {
      // console.log("Either category or store not selected: ", props.selectedCategory, props.stores);
      // return false;
    }
    let app_retailer_id = localStorage.getItem("app_retailer_id");
    MembersService.getCoupons(
      props.stores.selectedStore ? props.stores.selectedStore.id : 0,
      props.selectedCategory ? props.selectedCategory.id : 1,
      props.stores.selectedStore ? props.stores.selectedStore.retailer_id : app_retailer_id? app_retailer_id : props.cards.length > 0 ? props.cards[0].retailer_id : -1,
    )
      .then((responseJson) => {
        let spotlights = responseJson.data.items.filter((item:any)=>{
          if(item.title==="TARGETTED_FOR_BANNER"){
            return item;
          }
        })
        props.setSpotlight({spotlights:spotlights})

        let nextgen_BL_Array_API:any[] = []
        let nextgen_Ids_API:any[] = []
        let nextgen_Offers_Object_API:any[] = [];



        let offers:any[] = []
        let bestOffers:any[] = [];
        let allOffers = responseJson.data.items;

        // let nextGenOffers_LS = localStorage.getItem('nextGenOffers') || '{}'
        // let allNextGenOffersList_LS = localStorage.getItem('allNextGenOffersList') || '{}'
        // let allNextGenOffersList_LS_Object = JSON.parse(allNextGenOffersList_LS);
        // let nextGenOffers_LS_Object = JSON.parse(nextGenOffers_LS);
        // let nextGenOffers_LS_History = localStorage.getItem('nextGenOffersHistory') || '[]'
        // let nextGenOffers_LS_History_Object = JSON.parse(nextGenOffers_LS_History);
        // let nextGenOffers_LS_Ids = nextGenOffers_LS_Object && nextGenOffers_LS_Object.hasOwnProperty('ids')?nextGenOffers_LS_Object['ids']:[]



        responseJson.data.items.forEach((offer:any)=>{
          if(offer.hasOwnProperty('is_nextgen_offer') && offer.is_nextgen_offer === true){
            if(offer.hasOwnProperty('bl_message_ref') && offer.bl_message_ref !== "" && offer.bl_message_ref !== null){

              nextgen_BL_Array_API.push(offer.bl_message_ref)
              nextgen_Ids_API.push(offer.id)
              nextgen_Offers_Object_API.push(offer);

            }
          }

          if(offer.hasOwnProperty('default_target') && offer.default_target === false){
            bestOffers.push(offer)
          } else {
            offers.push(offer);
          }
        })


        // console.log(nextGenOffers_LS_Object)
        // Nextgen offers already in localstorage



          // let b = nextgen_Ids_API;

          // b.push(24)
          // new offers in API that are not in localstorage
          // let diff = nextgen_Ids_API.filter(n => !nextGenOffers_LS_Ids.includes(n))
          // console.log(diff)


        //if nextgen offers from API are different from localstorage
        //   if(diff.length>0){
            //find the difference if new nextgen offers are there
            // let offerForHistory = nextgen_Offers_Object_API.filter((offer) => {
            //   return diff.includes(offer.id);
            // })
            let bl_array = nextgen_Offers_Object_API.map(a => a.bl_message_ref);

            // Nextgen offers already in localstorage
            let retailer = props.retailer ? props.retailer : props.selectedStore.retailer ? props.selectedStore.retailer : {id:-1}
            let rtv = retailer && retailer.rtv_card_prefix ? retailer.rtv_card_prefix.replace(/^\D+/g, '').replace(/^0+/, ''): "";
            console.log(rtv)
            const holders = props.cards;

            let selectedStoreRetailerId = props.selectedStore ? parseInt(props.selectedStore.retailer_id) : -1;
            let holder = props.cards.filter(
                (c) => c.retailer_id === selectedStoreRetailerId
            )[0]


            // if(props.user && bl_array.length>0) {
            //   const COLLECT_URL= process.env.REACT_APP_COLLECT_API as string;
            //   const COLLECT_SUBSCRIPTION_KEY= process.env.REACT_APP_COLLECT_API_SUBSCRIPTION_KEY as string;
            //   let url = `${COLLECT_URL}?t=pageview&cs=mobile&cc=JPN&network_id=${rtv}&an=LCM&av=3.2&cid=${retailer.rtv_card_prefix}-${holder.ref}&ts=${Date.now()}&ads=${bl_array.toString()}&ca=member_id:${props.user.id}&ca=retailer_id:${retailer.id}&ca=offer_id:${nextgen_Ids_API.join(';')}`
            //   const options = {
            //     headers: {
            //       'Host': 'catalinamarketing-japan-np.azure-api.net',
            //       'Ocp-Apim-Subscription-Key': COLLECT_SUBSCRIPTION_KEY,
            //       'product': 'cwallet-api'
            //     }
            //   };
            //   fetch(url, options)
            //       .then(res => res.blob())
            //       .then(blob => {
            //         console.log(blob);
            //       });
            // }






            // nextGenOffers_LS_History_Object.unshift({
            //   ids:diff,
            //   timestamp:Date.now(),
            //   offers: offerForHistory
            // })

            // localStorage.setItem('nextGenOffersHistory',JSON.stringify(nextGenOffers_LS_History_Object))
            // console.log(offerForHistory)

            // nextgen_Offers_Object_API.forEach(offer=>{
            //   if(nextGenOffers_LS_Ids.indexOf(offer.id) === -1){
            //     nextGenOffers_LS_Ids.push(offer.id);
            //     if(nextGenOffers_LS_Object["offers"]){
            //       nextGenOffers_LS_Object["offers"].push(offer);
            //     } else {
            //       nextGenOffers_LS_Object["offers"] = [];
            //       nextGenOffers_LS_Object["offers"].push(offer);
            //     }
            //
            //   }
            // })
            // localStorage.setItem('nextGenOffers',JSON.stringify({
            //       ids:nextGenOffers_LS_Ids,
            //       timestamp:Date.now(),
            //       offers: nextGenOffers_LS_Object["offers"]
            //     }
            // }))
            // notificationsCount(arrayLS)
          // } else {
          //
            // notificationsCount(arrayLS)
          // }

            // notificationsCount(arrayLS)
          // } else {

            // notificationsCount(arrayLS)
          // }

        const first6 = allOffers.slice(0, 6);

        props.setOfferList([offers,bestOffers,allOffers, first6, nextgen_Offers_Object_API, nextgen_Ids_API]);
        props.offerListLoading(false);
        props.setFirstLoad(true)
        props.setAppLoading(false);
        // setBasketItemsCount(); Giving API Call twice
      })
      .catch((error) => {
        console.log(error);
        props.offerListLoading(false);
      });
  };

  const fetchMemberWallet = () => {
    MembersService.getWallet(null, null, props.stores.selectedStore.retailer_id)
      .then((responseJson) => {
        props.setMemberWallet(responseJson.data);
        props.setAllMemberWallet(responseJson.data);
        props.setAllMemberFilteredWallet(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setBasketItemsCount = () => {
    const user = JSON.parse(localStorage.getItem("user-data")!);
    if (!user) {
      return false;
    }
    props.fetchBasket();
  };

  useEffect(() => {
    fetchSpotlights();
    const user = JSON.parse(localStorage.getItem("user-data")!);
    if(user){
      props.setUser(user)
    }
    setSelectionMode(false)
    localStorage.removeItem('cache-cleaned');
    // setBasketItemsCount();
    fetchFavoriteStores();
    fetchHolderCards();
    fetchRetailers();
    
  }, []);

  useEffect(() => {
    // if (storesLoaded) {
    //   fetchCategories();
    // }
    if (categoriesLoaded && storesLoaded) {
      fetchOffers();
      setBasketItemsCount();
      props.fetchBasket();
      props.setAppLoading(false);
    }
  }, [props.selectedCategory, props.stores.selectedStore]);

  useEffect(() => {
    if (storesLoaded) {
      fetchCategories();
    }
  }, [props.stores.selectedStore]);

  useEffect(() => {
    if (props.stores.selectedStore) {
      fetchMemberWallet();
    }
  }, [props.stores.selectedStore]);

  useEffect(() => {
    if (props.event == 'CATEGORY_CHANGED' || props.event == 'STORE_CHANGED') {
      // props.removeEvent()
      triggerGTM({
        eventName: props.event,
        data: props.state,
      })
      // eventBus.dispatch("GTM_EVENT", {
      //   eventName: props.event,
      //   data: props.state,
      // });

    }
  }, [props.offers])

  useEffect(() => {
    if (props.event == 'OFFER_DETAILS') {
      triggerGTM({
        eventName: props.event,
        data: props.state,
      })
    }
  }, [props.event])
  useEffect(() => {
    // if (storesLoaded) {
    //   fetchCategories();
    // }
    if (categoriesLoaded && storesLoaded) {
      fetchOffers();
      setBasketItemsCount();
      props.fetchBasket();
      props.setAppLoading(false);
    }
  }, [categoriesLoaded, storesLoaded]);
  
  useEffect(() => {
    if (storesLoaded) {
      fetchCategories();
    }
  }, [storesLoaded]);

  useEffect(() => {
   console.log(props.selectedTab)
  }, [props.selectedTab]);

  const removeSubscription = () => {
    eventBus.remove("storeAddedToFavorites", fetchFavoriteStores);
  };

  useEffect(() => {
    eventBus.on("GTM_EVENT", async function (data: any) {

    });

  }, []);

  const triggerGTM = async (data: any) => {
    const eventProps = await data.data;
    let screenType = "";
    if (data.eventName === "OFFER_DETAILS") {
      screenType = "Detail View";
    } else {
      screenType = data.eventName === "account" ? "Others" : "List View";
    }
    window.dataLayer = window.dataLayer || [];
    // console.log({
    //   event: "virtualPageview",

    //   pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

    //   pageTitle: data.eventName, // fill dynamic uisng api value when page/state changes

    //   dm_offer_id: eventProps.offerDetails.selectedOffer.id, // fill dynamic uisng api value when page/state changes

    //   dm_basket_selected_offer: eventProps.offerDetails.selectedOffer.id,

    //   dm_basket_list: eventProps.offerList.basket
    //     ? eventProps.offerList.basket.map((b: { id: any }) => b.id).join("_")
    //     : 0,

    //   dm_offers: eventProps.offerList.offers
    //     ? eventProps.offerList.offers.map((b: { id: any }) => b.id).join("_")
    //     : 0, // fill dynamic uisng api value when page/state changes

    //   dm_store_number: eventProps.stores.selectedStore
    //     ? eventProps.stores.selectedStore.id
    //     : 0, // fill dynamic uisng api value when page/state changes

    //   dm_store_list: eventProps.stores.selectedStore
    //     ? eventProps.stores.favoriteStores
    //       .map((b: { id: any }) => b.id)
    //       .join("_")
    //     : 0,

    //   dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

    //   userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

    //   dm_retailer_id: eventProps.stores.selectedStore
    //     ? eventProps.stores.selectedStore.retailer.id
    //     : 0, //fill dynamic uisng api value when page/state changes

    //   dm_category_id: eventProps.categories.selectedCategory
    //     ? eventProps.categories.selectedCategory.id
    //     : 0, //fill dynamic uisng api value when page/state changes
    // })
    window.dataLayer.push({
      event: "virtualPageview",

      pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

      pageTitle: data.eventName, // fill dynamic uisng api value when page/state changes

      dm_offer_id: eventProps.offerDetails.selectedOffer.id, // fill dynamic uisng api value when page/state changes

      dm_basket_selected_offer: eventProps.offerDetails.selectedOffer.id,

      dm_basket_list: eventProps.offerList.basket
        ? eventProps.offerList.basket.map((b: { id: any }) => b.id).join("_")
        : 0,

      dm_offers: eventProps.offerList.offers
        ? eventProps.offerList.offers.map((b: { id: any }) => b.id).join("_")
        : 0, // fill dynamic uisng api value when page/state changes

      dm_store_number: eventProps.stores.selectedStore
        ? eventProps.stores.selectedStore.id
        : 0, // fill dynamic uisng api value when page/state changes

      dm_store_list: eventProps.stores.selectedStore
        ? eventProps.stores.favoriteStores
          .map((b: { id: any }) => b.id)
          .join("_")
        : 0,

      dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

      userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

      dm_retailer_id: eventProps.stores.selectedStore
        ? eventProps.stores.selectedStore.retailer.id
        : 0, //fill dynamic uisng api value when page/state changes

      dm_category_id: eventProps.categories.selectedCategory
        ? eventProps.categories.selectedCategory.id
        : 0, //fill dynamic uisng api value when page/state changes
    });
    props.removeEvent()
  }

  useEffect(() => {
    eventBus.on("storeAddedToFavorites", fetchFavoriteStores);
    return removeSubscription;
  }, []);

  const tabRef = useRef(null);

  useEffect(() => {
    if (!props.isAppLoading) {
      const tabs = tabRef.current;
      if (tabs && tabs.ionTabContextState) {
        props.setSelectedTab(tabs.ionTabContextState.activeTab);
        
        triggerGTM({
          eventName: tabs.ionTabContextState.activeTab,
          data: props.state,
        })
        // eventBus.dispatch("GTM_EVENT", {
        //   eventName: tabs.ionTabContextState.activeTab,
        //   data: props.state,
        // });
      }
    }
  }, [props.isAppLoading]);
  const holders = props.cards;
  let holder = undefined;
  if(props.selectedStore){
    holder = props.cards.filter(
        (c) => c.retailer_id === parseInt(props.selectedStore.retailer_id)
    )[0]
  }

  // console.log(holder)
  // console.log(`wow holder for tabs is ${holder}`)
  return (
    <>
      <IonTabs
        ref={tabRef}
        className="bg-white"
        onIonTabsDidChange={(evt: any) => {
          triggerGTM({
            eventName: evt.detail.tab,
            data: props.state,
          })
          if(evt.detail.tab==='home'||evt.detail.tab==='basket'||evt.detail.tab==='coupons'||evt.detail.tab==='notifications'){
            props.setAccountMode(false);
          } else {
            props.setAccountMode(true)
          }
          // eventBus.dispatch("GTM_EVENT", {
          //   eventName: evt.detail.tab,
          //   data: props.state,
          // });
          console.log(evt.detail.tab)
          props.setSelectedTab(evt.detail.tab);
          props.fetchBasket();
        }}
      >
        <IonRouterOutlet>
          <Redirect exact path="/tabs" to="/tabs/home" />
          <Route path="/tabs/home" render={() => <Screen14 />} exact={true} />
          <Route
            path="/tabs/coupons"
            render={() => <ScreenCoupons />}
            exact={true}
          />
          {/*<Route*/}
          {/*    path="/tabs/notifications"*/}
          {/*    render={() => <Notifications />}*/}
          {/*    exact={true}*/}
          {/*/>*/}
          <Route path="/tabs/basket" render={() => <Screen16 />} exact={true} />
          <Route path="/tabs/23" render={() => <Screen23 />} exact={true} />
          <Route
            path="/tabs/account/retailer/:id"
            render={() => <Screen24Retailer />}
            exact={true}
          />
          <Route
            path="/tabs/account"
            render={() => <Screen24 />}
            exact={true}
          />
          <Route
            path="/tabs/account/retailer-about"
            render={() => <AccountRetailerAbout />}
            exact={true}
          />
          <Route
            path="/tabs/account/settings"
            render={() => <AccountSettings />}
            exact={true}
          />
          <Route
            path="/tabs/account/editing"
            render={() => <AccountEditing />}
            exact={true}
          />
          <Route
            path="/tabs/account/faq"
            render={() => <AccountFaq />}
            exact={true}
          />
          <Route
            path="/tabs/account/terms"
            render={() => <AccountTerms />}
            exact={true}
          />
          <Route
            path="/tabs/account/privacy"
            render={() => <AccountPrivacy />}
            exact={true}
          />
          <Route
            path="/tabs/account/inquiry"
            render={() => <AccountInquiry />}
            exact={true}
          />
        </IonRouterOutlet>

        <IonTabBar className="pt-1 pb-4 border-t border-gray-200" slot="bottom">
          <IonTabButton tab="home" href="/tabs/home">
            <div className="relative">
              <img
                style={{marginTop: -1, height: 23, width: 23}}
                src={home}
                className={classNames(
                  "w-6 h-6 object-contain",
                  props.selectedTab === "home" ? "opacity-80" : "opacity-30"
                )}
              />
              {((props.selectedStore === null &&
                props.isStoresLoading === false) ||
                (props.cards.length === 0 && props.cardsLoading === false)) && (
                  <div className="w-2 h-2 rounded-full absolute top-1 -right-1" style={{background: "#FF334B"}}>
                    &nbsp;
                  </div>
                )}
            </div>

            <div
              className={classNames(
                "text-xs",
                props.selectedTab === "home" ? "text-black" : "text-gray-300"
              )}
            >
              トップ
            </div>
          </IonTabButton>

          {/*<IonTabButton tab="coupons" href="/tabs/coupons">*/}
          {/*  <div className="mb-1">*/}
          {/*    <img*/}
          {/*      style={{marginTop: -1, height: 23, width: 23}}*/}
          {/*      src={coupons}*/}
          {/*      className={classNames(*/}
          {/*        "w-6 h-6 object-contain",*/}
          {/*        props.selectedTab === "coupons" ? "opacity-80" : "opacity-30"*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    className={classNames(*/}
          {/*      "text-xs",*/}
          {/*      props.selectedTab === "coupons" ? "text-black" : "text-gray-300"*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    クーポン*/}
          {/*  </div>*/}
          {/*</IonTabButton>*/}

          <IonTabButton tab="basket" href="/tabs/basket">
            <div className="mb-1">
              <img
                style={{marginTop: -1, height: 23, width: 23}}
                src={basket}
                className={classNames(
                  "w-6 h-6 object-contain",
                  props.selectedTab === "basket" ? "opacity-80" : "opacity-30"
                )}
              />
            </div>
            <div
              className={classNames(
                "text-xs",
                props.selectedTab === "basket" ? "text-black" : "text-gray-300"
              )}
            >
              バスケット
            </div>

            <IonBadge>{props.selectedStore && holder ? props.basket.length : 0}</IonBadge>
          </IonTabButton>

          <IonTabButton tab="account" href="/tabs/account">
            <div className="mb-1">
              <img
                style={{marginTop: -1, height: 23, width: 23}}
                src={account}
                className={classNames(
                  "w-6 h-6 object-contain",
                  props.selectedTab === "account" ? "opacity-80" : "opacity-30"
                )}
              />
            </div>
            <div
              className={classNames(
                "text-xs",
                props.selectedTab === "account" ? "text-black" : "text-gray-300"
              )}
            >
              アカウント
            </div>
          </IonTabButton>
          {/*<IonTabButton tab="notifications" href="/tabs/notifications">*/}

          {/*  <div className="mb-1">*/}

          {/*    <img*/}
          {/*        style={{marginTop: -1, height: 23, width: 23}}*/}
          {/*        src={notification}*/}
          {/*        className={classNames(*/}
          {/*            "w-6 h-6 object-contain",*/}
          {/*            props.selectedTab === "notifications" ? "opacity-80" : "opacity-30"*/}
          {/*        )}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*      className={classNames(*/}
          {/*          "text-xs",*/}
          {/*          props.selectedTab === "notifications" ? "text-black" : "text-gray-300"*/}
          {/*      )}*/}
          {/*  >*/}
          {/*    通知*/}
          {/*  </div>*/}
          {/*  <IonBadge>{props.notificationsCount ? props.notificationsCount : 0}</IonBadge>*/}
          {/*</IonTabButton>*/}
        </IonTabBar>
      </IonTabs>
      <br />
    </>
  );
};

export const CouponsTabs = connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponsTabsComponent);
